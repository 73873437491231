<template>
  <v-container fluid>
      <v-row>
          <v-col>
              <v-dialog v-model="dialog" persistent fullscreen transition="dialog-bottom-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class=" blue--text mb-2 accent-2" outlined v-bind="attrs" v-on="on" @click="getItemsReference(); getRepresntativeReference();"><v-icon>mdi-plus</v-icon> گواستنەوەی کاڵا</v-btn>
                </template>
                <v-card>
                    <v-toolbar dark outlined :class="isUpdate?'orange accent-2':'blue accent-2'" elevation="1" >
                    <v-btn icon @click="dialog =false; reset()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn  text @click="isUpdate? update():create()" >
                        <v-icon>{{isUpdate? 'mdi-check':'mdi-plus'}}</v-icon> {{isUpdate? 'نوێکردنەوەی گواستنەوە': 'زیادکردنی گواستنەوە'}} </v-btn>
                    </v-toolbar-items>
                    </v-toolbar>
                    <v-form ref="transfer" @submit.prevent>
                        <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="2" lg="2" sm="12">
                               <v-autocomplete :items="representativeReference" item-text="full_name" item-value="user_id" dense outlined label="ناوی مەندووب" v-model="transfer.representative_id" :rules="rules.representative"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="2" lg="2" sm="12">
                              <v-select :items="[{text: 'بارکردن', value: 'g'},{text:'گەڕانەوە', value: 'r'}]" item-text="text" item-value="value"  dense label="جۆری گواستنەوە" outlined v-model="transfer.transfer_type"></v-select>
                            </v-col>
                            
                            <v-col cols="12" md="2" lg="2" sm="12">
                                <v-menu
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field @focus="$event.target.select()"
                                        v-model="transfer.date"
                                        label="بەروار"
                                        prepend-inner-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="transfer.date"
                                    @input="menu1 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <hr>
                        <br>
                        <v-row>
                            <v-col>
                                <v-data-table  class="elevation-2 rounded-lg"  :item-class="itemRowBackground" :headers="secondHeaders" :items="transfer.transfer_items" hide-default-footer>
                                    <template v-slot:[`item.total_item`]="{ item }">
                                        {{Number(item.item_per_unit * item.qty_unit) + Number(item.qty_item)}}
                                    </template>
                                    <template v-slot:[`item.item_name`]="item">
                                        {{ item.item.item_name }}
                                    </template>
                                    <template v-slot:[`item.qty_item`]="item">
                                        <v-edit-dialog
                                        >
                                        {{ item.item.qty_item }}
                                        <template v-slot:input>
                                            <v-text-field @focus="$event.target.select()" :disabled="item.item.delete" class="my-5" :rules="rules.numberRule" label="بڕ بە دانە" v-model="item.item.qty_item" type="number"></v-text-field>
                                        </template>
                                        </v-edit-dialog>
                                    </template>
                                    <template v-slot:[`item.qty_unit`]="item">
                                        <v-edit-dialog
                                        >
                                        {{ item.item.qty_unit }}
                                        <template v-slot:input>
                                            <v-text-field @focus="$event.target.select()" :disabled="item.item.delete" :rules="rules.numberRule" class="my-5" :label="'بڕ بە (' + item.item.unit_name + ')'" v-model="item.item.qty_unit" type="number"></v-text-field>
                                        </template>
                                        </v-edit-dialog>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon" >
                                            <v-icon large class="ml-2" v-if="hover" :color="item.delete?'white accent-2':'red accent-2'" id="icon" @click="removeItemToggle(item)">{{item.delete? 'mdi-restore':'mdi-delete'}}</v-icon>
                                            <v-icon large class="ml-2" v-else >{{item.delete? 'mdi-restore':'mdi-delete'}}</v-icon>
                                        </v-hover>
                                    </template>
                                </v-data-table>
                                <v-autocomplete class="mt-5"  :items="itemReference" label="ناوی کاڵا" outlined dense item-text="item_name" item-value="item_id" v-model="transfer_item" return-object @change="selectItem($event)"></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-form>
                </v-card>
              </v-dialog>
              <v-card>
                  <v-data-table class="elevation-2 rounded-md" :headers="headers" :items="transfers" hide-default-footer  :items-per-page="20"  :loading="loading">
                      <template v-slot:top>
                            <v-text-field @focus="$event.target.select()"
                            v-model="table.search"
                            label="گەڕان"
                            class="mx-4"
                            ></v-text-field>
                        </template>
                        <template v-slot:[`item.transfer_type`]="{ item }">
                            {{item.transfer_type === 'g'?'بارکردن':'گەڕانەوە'}}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="75" id="icon" >
                            <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon" @click="prepairEdit(item.transfer_id)">mdi-pencil</v-icon>
                            <v-icon large class="ml-2" v-else>mdi-pencil</v-icon>
                            </v-hover>
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon" >
                                <v-icon large class="ml-2" v-if="hover" color="red accent-2" id="icon" @click="remove(item.transfer_id)">mdi-delete</v-icon>
                                <v-icon large class="ml-2" v-else >mdi-delete</v-icon>
                            </v-hover>
                        </template>
                  </v-data-table>
              </v-card>
              <v-pagination color="orange accent-2" :length="Math.ceil(table.numberOfTransfers / 20)"  v-model="table.page"></v-pagination>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            menu1: false,
            loading: false,
            table:{
                numberOfTransfers: 0,
                page: 1,
                search: ''
            },
            transfer:{
                transfer_id: 0,
                representative_id: 0,
                transfer_type: 'g',
                date: new Date().toISOString().split('T')[0],
                transfer_items: []
            },
            representativeReference: [],
            itemReference: [],
            transfers: [],
            transfer_item: {},
            headers: [
                {text: 'ژمارەی وەصل', value: 'transfer_id', align: 'center'},
                {text: 'جۆری گواستنەوە', value: 'transfer_type', align: 'center'},
                {text: 'بەروار', value: 'date', align: 'center'},
                {text: 'ناوی مەندووب', value: 'representative_name', align: 'center'},
                {text: 'کردارەکان', value: 'actions', align: 'center'},
            ],
            secondHeaders: [
                {text: 'ناوی کاڵا', value: 'item_name', align: 'center'},
                {text: 'بڕ بە یەکە', value: 'qty_unit', align: 'center'},
                {text: 'بڕ بە دانە', value: 'qty_item', align: 'center'},
                {text: 'کۆی دانە', value: 'total_item', align: 'center'},
                {text: 'کردارەکان', value: 'actions', align: 'center'},
            ],
            rules: {
                representativeRule: [
                    (v) => {
                        return (!(v <= 0) || 'تکایە مەندووب دیاریبکە')
                    }
                ]
            }
        }
    },
    methods: {
        async getTransfers(){
            try {
                this.loading = true
                const {data} = await this.axios.get('/transfer', {params:{page:this.table.page, search: this.table.search}})
                data.transfers.forEach(transfer => {
                    transfer.date = new Date(transfer.date).toISOString().split("T")[0]
                })
                this.transfers = data.transfers
                this.table.numberOfTransfers = data.count
                this.loading = false
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
            }
        },
        async create(){
            if (this.$refs.transfer.validate()) {
                if (this.transfer.transfer_items.length > 0) {
                    const transfer = {...this.transfer}
                    transfer.transfer_items.forEach(transfer_item => {
                        transfer_item.qty = (Number(transfer_item.qty_unit) * Number(transfer_item.item_per_unit)) + Number(transfer_item.qty_item)
                        transfer_item = this.removeProperty(transfer_item, ['item_name', 'item_per_unit', 'unit_name', 'cost_price', 'item_price', 'item_price_wholesale', 'qty_item', 'qty_unit'])
                    })
                    try {
                        await this.axios.post('/transfer', transfer)
                        this.reset()
                        this.getTransfers()
                        this.dialog = false
                        this.emitter.$emit('notify', {type: 'success', text:'ووەصڵەکە زیادکرا'})
                    } catch (error) {
                        console.log(error);
                        if (error.response.status == 422) {
                            return this.emitter.$emit('notify', {type: 'error', text:`تکایە هەموو بەشەکان بە تەواوی پڕبکەروە!`})
                        }
                        this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
                    }
                }else{
                    this.emitter.$emit('notify', {type: 'error', text:'هیچ کاڵایەک زیاد نەکراوە'})
                }
                
            }
        },
        async prepairEdit(id){ 
            this.getItemsReference()
            this.getRepresntativeReference()
             try {
                 const {data} = await this.axios.get('/transfer/'+id)
                 data.date = new Date(data.date).toISOString().split("T")[0]
                 data.transfer_items.forEach(transfer_item => transfer_item.delete = false)
                 this.transfer = data
                 this.dialog = true
             } catch (error) {
                 console.log(error);
                    this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
             }
        },
        async update(){
            if (this.$refs.transfer.validate()) {
                try {
                    const transfer = {...this.transfer}
                    transfer.transfer_items.forEach(transfer_item => {
                        transfer_item.qty = (Number(transfer_item.qty_unit) * Number(transfer_item.item_per_unit)) + Number(transfer_item.qty_item)
                        transfer_item = this.removeProperty(transfer_item, ['item_name', 'item_per_unit', 'unit_name', 'cost_price', 'item_price', 'item_price_wholesale', 'qty_item', 'qty_unit'])
                    })
                    await this.axios.patch('/transfer/'+ this.transfer.transfer_id, transfer)
                    this.getTransfers()
                    this.reset()
                    this.dialog = false
                    this.emitter.$emit('notify', {type: 'success', text:'وەصڵەکە نوێکرایەوە'})

                } catch (error) {
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', {type: 'error', text:`${error.response.data.field} is duplicate`})
                    }
                    this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
                }
            }
        },
        async remove(id){
            this.$confirm('ئایا دڵنیاییت لە سڕینەوەی ئەم وەصڵە؟', 'دڵنیابوونەوە', 'question').then(async () => {
                try {
                    await this.axios.delete('/transfer/'+id)
                    this.getTransfers()
                } catch (error) {
                    this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
                }
            })
        },
        async getItemsReference(){
            try {
                const {data} = await this.axios.get('/item/forReference')
                this.itemReference = data
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
            }
        },
        async getRepresntativeReference(){
            try {
                const {data} = await this.axios.get('/user/representative')
                this.representativeReference = data
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
            }
        },
        removeItemToggle(item){
        if (item.transfer_item_id) {
            if (item.delete) {
                item.delete = false
            }else{
                this.$confirm('ئایا دڵنیاییت لە سڕینەوەی ئەم کاڵایە؟', 'دڵنیابوونەوە', 'question').then(async () => {
                item.delete = true
                })
            }
            
        }else{
            this.transfer.transfer_items = this.transfer.transfer_items.filter(t_item => t_item.item_id != item.item_id)
        }
        
        },
        itemRowBackground: function (item) {
            return item.delete  ? 'red accent-2' : ''
        },
        async selectItem(val){
            
                const [t_item] = this.transfer.transfer_items.filter(transfer_item => transfer_item.item_id == val.item_id)
                
                if (!t_item) this.transfer.transfer_items.push({...val,qty_unit:0, qty_item:0})  
                this.transfer_item ={}
            
        },
        removeProperty(obj = {}, fields){
            for (let i = 0; i < fields.length; i++)  delete obj[fields[i]]
        },
        reset(){
            this.transfer = {               
                transfer_id: 0,
                representative_id: 0,
                transfer_type: 'g',
                date: new Date().toISOString().split('T')[0],
                transfer_items: []
            }
            this.$refs.transfer.resetValidation();
        },
    },
    computed:{
        isUpdate(){
            return this.transfer.transfer_id !== 0
        }
    },
    created(){
        this.getTransfers()
    }
}
</script>

<style>

</style>